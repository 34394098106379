html,
body {
  height: 100%;
  min-height: 100%;
  color: #1c1c1c;
}

a,
button,
input {
  outline: none !important;
}

/* Document root element, created by Next.js */
#__next {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

/* Fix issue with blurry resized images in Chrome */
/* https://stackoverflow.com/a/69137897 */
@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  img {
    image-rendering: -webkit-optimize-contrast;
  }
}

/* Unset for Safari 11+ */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    img {
      image-rendering: unset;
    }
  }
}
