/* TextField */
body .MuiFormLabel-root {
  font-family: inherit;
  letter-spacing: inherit;
  color: #828282;
}

body .MuiFormLabel-root.Mui-disabled {
  color: #828282;
}

body .MuiFormLabel-root.Mui-focused {
  color: #1c1c1c;
}

body .MuiInputBase-root {
  font-family: inherit;
  letter-spacing: inherit;
  color: #1c1c1c;
}

body .MuiInputBase-root.Mui-disabled {
  color: #828282;
}

body .MuiInputBase-input:-webkit-autofill {
  box-shadow: 0 0 0 30px #f5f5f3 inset !important;
}

body .MuiInput-underline:before {
  border-bottom-color: #828282;
}

body .MuiInput-underline:hover:not(.Mui-disabled)::before {
  border-bottom-color: #1c1c1c;
  border-bottom-width: 1px;
}

body .MuiInput-underline.Mui-disabled:before {
  border-bottom-color: #bdbdbd;
  border-bottom-style: solid;
}

body .MuiInput-underline:after {
  border-bottom-color: #1c1c1c;
  border-bottom-width: 1px;
}

/* Select */
body .MuiSelect-select:focus {
  background-color: transparent;
}

body .MuiCheckbox-colorPrimary.Mui-checked {
  color: #828282;
}

body .MuiSelect-icon {
  color: #bdbdbd;
}

body .MuiSelect-icon.Mui-disabled {
  color: transparent;
}

body .MuiSelect-nativeInput {
  width: 100%;
  white-space: nowrap;
}

body .MuiInputLabel-shrink {
  white-space: nowrap;
}

body label + .MuiInput-formControl {
  margin-top: 0px;
}

body .MuiInputLabel-formControl {
  position: relative;
}

body .MuiInput-input[readonly] {
  color: #bdbdbd;
}
